import React from 'react';
import '../AboutMe/AboutMe.scss'

export default function AboutMe() {
    return (
        <section className="about-container" id="123456">
            <div className="about">
                <h3 className="about-title">About Me</h3>
                <p className="about-text">
                I am a dedicated and proactive junior web developer with proficient JavaScript and React skills. I have experience working in an agile environment and collaborating with different roles on group projects. In my leisure time, I am passionate about learning various programming languages and frameworks, singing, and hiking.  
                </p>
            </div>
            <div>
                <h3 className=" about-title__tablet">About Me</h3>
            </div>
        </section>
    )
}
