import React, { Component } from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import './App.scss';
import Home from './pages/HomePage/HomePage';
import Contact from './pages/ContactPage/ContactPage';

export default class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="contact" element={<Contact/>} />
        </Routes>
      </BrowserRouter>
    )
  }
}

