import { useState } from 'react';
import './Nav.scss';
import NavLinks from '../NavBar/NavLinks';
import {AiOutlineClose, AiOutlineMenu} from 'react-icons/ai'

import React from 'react'

export default function NavMobile() {

    const [open, setOpen] = useState(false);

    const openIcon = <AiOutlineMenu className="nav-toggle" onClick={() => setOpen(!open)} size="1.8rem" />
    const closeIcon = <AiOutlineClose className="nav-toggle" onClick={() => setOpen(!open)} size="1.8rem" />

    return (
        <div className="nav-mobile">
            {/* <a href="/" className="nav-logo">
                <h1 className="nav-logo__text">Christy Suen</h1> 
            </a> */}
            {/* <div className="nav-toggle" onClick={() => setOpen(!open)}>
                <span className="nav-toggle__line"></span>
                <span className="nav-toggle__line"></span>
                <span className="nav-toggle__line"></span>
            </div>  */}
            {open ? closeIcon : openIcon}
            {open && <NavLinks />}
        </div>
    )
}
