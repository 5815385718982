import React, { Component } from 'react';
import Nav from '../../components/NavBar/Nav';
import Hero from '../../components/Hero/Hero';
import About from '../../components/AboutMe/AboutMe';
import './HomePage.scss'

export default class HomePage extends Component {
    render() {
        return (
            <section>
                <div className="home-page">
                    <div className="box">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <Nav />
                    <Hero />
                    <About />
                </div>
            </section>
        )
    }
}
