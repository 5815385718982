import React, { Component } from 'react';
import resume from '../../assets/resume.pdf';
import './Nav.scss';

export default class NavLinks extends Component {
    onResumeClick(){
        window.open(resume);
    }
    render() {
        return (
            <ul className="nav-list" >
                <li onClick={this.onResumeClick} className="nav-list__link nav-list__link--border">Resume</li>
                {/* <li className="nav-list__link">Hire Me</li>
                <li className="nav-list__link">About Me</li>
                <li className="nav-list__link">Projects</li> */}
            </ul> 
        )
    }
}
