import React from 'react';
import './Hero.scss'
import Christy from '../../assets/images/cs.png';
// import {MdOutlineExpandMore} from 'react-icons/md';

export default function hero() {
    return (
        <section className="hero-section">
            <div className="hero-container">
                <div className="hero">
                    <div className="hero-title">
                        <h2 className="hero-title__left">HELLO</h2>
                        <h2 className="hero-title__right">WORLD</h2>
                        <h2 className="hero-title__full">HELLO WORLD</h2>
                    </div>
                    <div className="hero-content">
                        <p className="hero-content__text">Welcome to my website.
                        This is Christy. I'm a Web Developer. I'll keep update this website. If you want to know more about me, check out the next section.</p> 
                        {/* If you want to know more about me,  click the button below. */}
                        {/* <button className="hero-button">
                            <a href="#123456" className="hero-button__text">About Me</a>
                        </button>  */}
                    </div>
                </div> 
                <div className="hero-img">
                    <img className="hero-img__img" src={Christy} alt="Christy" />
                </div>
            </div>
        </section>
    )
}
