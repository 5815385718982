import React, { Component } from 'react';
import './Nav.scss';
import NavMobile from '../NavBar/NavMobile';
import NavTablet from '../NavBar/NavTablet';



export default class Nav extends Component {
    
    render() {
        return (
            <section className="nav">
                <a href="/" className="nav-logo">
                <h1 className="nav-logo__text">Christy Suen</h1> 
                </a>
                <NavMobile />
                <NavTablet />
            </section>
        )
    }
}