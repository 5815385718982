import React, { Component } from 'react';
import NavLinks from '../NavBar/NavLinks';
import './Nav.scss';

export default class NavTablet extends Component {
    render() {
        return (
            <section className="nav-tablet">
                {/* <a href="/" className="nav-logo">
                <h1 className="nav-logo__text">Christy Suen</h1> 
                </a> */}
                <NavLinks />
            </section>
        )
    }
}
